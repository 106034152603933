.root {
  font-family: 'Lato', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  @apply bg-accent-1 text-accent-3 border-accent-2 cursor-not-allowed;
}

.primary {
  background-color: rgba(39, 39, 39, 100);
  color: rgba(255, 255, 255, 100);
  border-radius: 0.125rem;
  border: none;
  cursor: pointer;
}

.primary:hover {
  background: rgb(70, 69, 68);
}

.secondary {
  background-color: rgb(244, 244, 244);
  border: 0.063rem solid transparent;
  cursor: pointer;
  border-radius: 0.125rem;
}

.secondary:hover {
  background-color: rgb(255, 255, 255);
  border: 0.063rem solid rgb(201, 201, 201);
  border-radius: 0.125rem;
}

.tertiary {
  border: none;
  background-color: rgb(250, 250, 250);
  border-radius: 0.125rem;
  cursor: pointer;
}

.tertiary:hover {
  background-color: rgb(244, 244, 244);
  border-radius: 0.125rem;
}

.transparent {
  color: rgb(147, 147, 147);
  border: 0.063rem solid rgb(147, 147, 147);
  border-radius: 0.125rem;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.transparent:hover {
  border: 0.063rem solid rgb(70, 69, 68);
  border-radius: 0.125rem;
  color: rgb(70, 69, 68);
}

.outlined {
  color: rgb(255, 255, 255);
  border: 0.063rem solid rgb(255, 255, 255);
  background: transparent;
  border-radius: 0.125rem;
  cursor: pointer;
}

.outlined:hover {
  color: rgb(201, 201, 201);
  border: 0.063rem solid rgb(201, 201, 201);
  border-radius: 0.125rem;
  cursor: pointer;
}

.primary:active,
.secondary:active,
.tertiary:active,
.transparent:active,
.outlined:active {
  color: rgb(147, 147, 147);
  background-color: rgb(250, 250, 250);
  border-radius: 0.125rem;
}

.disabled {
  background: rgb(250, 250, 250);
  border-radius: 0.125rem;
  color: rgb(147, 147, 147);
  cursor: not-allowed;
  border: none;
}

.small {
  padding: 0.5rem 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.813rem;
  line-height: 1rem;
}

.medium {
  padding: 0.75rem 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.813rem;
  line-height: 1rem;
}

.large {
  padding: 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.813rem;
  line-height: 1rem;
}

.start {
  display: flex;
  align-items: center;
}

.end {
  display: flex;
  align-items: center;
}

.start img {
  padding-right: 7px !important;
}

.end img {
  padding-left: 7px !important;
}
